<template>
  <div class="has-nav-bar view-inspection-detail">
    <van-nav-bar title="巡检信息" fixed left-arrow @click-left="onClickLeft">
      <div slot="right" class="nav-right">
        <van-icon
          class-prefix="iconfont"
          name="ico-plus"
          @click="onClickRight"
        />
      </div>
    </van-nav-bar>
    <div class="view-inspection-detail__container">
      <van-form
        :show-error-message="false"
        label-width="28vw"
        class="van-form__text-color"
      >
        <van-field
          v-model="form.name"
          readonly
          disabled
          label="巡检名称"
          placeholder="--"
        >
        </van-field>
        <van-field
          v-model="form.departmentName"
          readonly
          disabled
          label="所属部门"
          placeholder="--"
        >
        </van-field>
        <van-field readonly disabled label="巡检人" placeholder="--">
          <template #input>
            <span v-if="form.peopleNames" class="disabled-color">
              {{ form.peopleNames.join("、") }}
            </span>
          </template>
        </van-field>
        <van-field
          v-model="form.time"
          readonly
          disabled
          label="巡检日期"
          placeholder="--"
        >
        </van-field>
        <van-field
          v-model="form.shift"
          readonly
          disabled
          label="班次"
          placeholder="--"
        >
        </van-field>
      </van-form>
      <hr />
      <van-list
        v-model="isLoading"
        :finished="isFinished"
        finished-text=""
        :error.sync="isError"
        error-text="加载失败，请稍后再试！"
        @load="getList"
      >
        <van-cell
          v-for="(item, idx) in list"
          :key="idx"
          class="view-inspection-detail__rows"
          :title="item.equipmentName"
          is-link
          @click="gotoDetails(item)"
        >
          <template #label>
            <van-icon
              class="view-inspection__rows-timeicon"
              class-prefix="iconfont"
              name="ico-timerange"
            />
            <span>{{ item.time }}</span>
          </template>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
import { NavBar, List, SwipeCell, Icon, Field, Form, Cell } from "vant";
import { mapState } from "vuex";
import { getInspectionEquipmentList, getInspectionInfo } from "../../api/psm";

export default {
  name: "InspectionDetail",
  components: {
    [NavBar.name]: NavBar,
    [SwipeCell.name]: SwipeCell,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Form.name]: Form,
    [Cell.name]: Cell,
    [List.name]: List
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  data() {
    return {
      form: {},
      list: [],
      total: 0,
      isLoading: false,
      isFinished: false,
      isError: false,
      query: {
        page: 1,
        size: 10
      }
    };
  },
  watch: {},
  async created() {
    this.getData();
    this.getList();
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    onClickRight() {
      this.$router.push({
        name: "inspectionEquipmentAdd",
        params: {
          id: this.$route.params.id,
          type: "add"
        }
      });
    },
    async getData() {
      this.form = await getInspectionInfo(this.$route.params.id);
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        // 查询前去掉参数
        const { list, total } = await getInspectionEquipmentList({
          ...this.query,
          infoId: this.$route.params.id
        });
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.query.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    gotoDetails(item) {
      this.$router.push({
        name: "inspectionEquipmentDetail",
        params: {
          id: item.infoId,
          equipmentId: item.id
        }
      });
    }
  }
};
</script>
<style lang="scss">
.view-inspection-detail {
  &__container {
    height: calc(100vh - 58px);
    overflow: auto;
  }
  &__nav-right--icons {
    font-size: 15px;
  }
  &__header {
    height: 165px;
    margin-bottom: 12px;
  }
  &__header-feature {
    display: flex;
    justify-content: space-between;
    margin: 0 16px;
    &--left {
      color: $--color-gray-list-page;
    }
    &--right {
      color: $--color-icon;
    }
  }
  &__rows {
    align-items: center;
    .van-cell__title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .van-cell__label {
      color: $--color-gray-list-page;
    }
    &-left {
      width: 80vw;
    }
    &-top {
      color: $--color-black-list-page;
      p {
        font-size: 14px;
      }
      span {
        font-size: 13px;
        color: $--color-gray-list-page;
      }
    }
    &-timeicon {
      font-size: 13px;
      margin-right: 5px;
    }
    &-icon {
      font-size: 42px;
      line-height: 42px;
    }
    &-bottom {
      font-size: 13px;
      p {
        margin: 6px 0;
        margin-bottom: 6px;
        line-height: 1.1;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
</style>
